import * as Sentry from "@sentry/react";

interface ErrorDropper {
  info: string;
  types: string[];
  values: string[]
}

interface SentryError {
  type: string;
  value: string;
}

const errorDroppers: ErrorDropper[] = [
  {
    info: "Filter out UnhandledRejection errors that have no information",
    types: ["UnhandledRejection", "Error"],
    values: [
      "Non-Error promise rejection captured",
      "Non-Error exception captured",
    ],
  },
];

const getSentryException = (event: Sentry.ErrorEvent): Sentry.Exception | null => {
  const e = event?.exception?.values?.[0];
  if (
    event !== undefined &&
    event.exception !== undefined &&
    event.exception.values !== undefined &&
    event.exception.values.length !== 0
  ) {
    return event.exception.values[0];
  }
  return null;
}

const checkErrorIsDropped = (event: Sentry.ErrorEvent): ErrorDropper | null => {
  const e = getSentryException(event);
  if (e !== null) {
    for (let errorDropper of errorDroppers) {
      if (
        errorDropper.types.includes(e.type!) &&
        errorDropper.values.some((elem) => e.value?.includes(elem))
      ) {
        return errorDropper;
      }
    }
  }
  return null;
};

const dsn = import.meta.env.VITE_SENTRY_DSN;

if (dsn) {
  Sentry.init({
    dsn,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    beforeSend: (event) => {
       // avoid sending certain events to Sentry
      const errorDropper = checkErrorIsDropped(event);
      if (errorDropper) {
        console.log('Error will not be sent to Sentry:', getSentryException(event))
        console.log('Info:', errorDropper.info);
        return null;
      }
      return event;
    },
  });
} else {
  console.warn("Sentry DSN not found, error tracking disabled");
}

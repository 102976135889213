import "./sentry";
import React from "react";
import ReactDOM from "react-dom/client";
import { ChakraProvider } from "@chakra-ui/react";
import { ToastContainer } from "react-toastify";
import { BrowserRouter as Router } from "react-router-dom";
import "./index.css";
import App from "./App";
import { AppProvider } from "./store";
import * as serviceWorker from "./serviceWorker";
import theme from "./theme";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <AppProvider>
        <ToastContainer />
        <Router>
          <App />
        </Router>
      </AppProvider>
    </ChakraProvider>
  </React.StrictMode>
);

// Optional: Service worker registration
// If you're using service workers, consider updating to Workbox or similar.
serviceWorker.unregister(); // or serviceWorker.register();

import React from "react";
import { Box, Flex } from "@chakra-ui/react";
import { useParams } from "react-router-dom";

import { decode } from "../utils/Base64";
import useInvoiceInfo from "../hooks/useInvoiceInfo";
import useSelectLocale from "../hooks/useSelectLocale";
import useDocumentTitle from "../hooks/useDocumentTitle";
import { PENDING, RouteParams } from "../types";
import ProductOverview from "../components/ProductOverview";
import TermsAndPrivacyLinks from "../components/TermsAndPrivacyLinks";
// import CheckoutForm from '../components/CheckoutForm';
import BrandHeader from "../components/BrandHeader";
import FeedbackMessage from "../components/FeedbackMessage";
import NotFound from "./NotFound";

function Pending() {
  const { invoiceHash, clientHash } = useParams() as RouteParams;

  const invoiceNumber = decode(invoiceHash);
  const clientNumber = decode(clientHash);
  const {
    data,
    isFetched: isReady,
    hasError,
  } = useInvoiceInfo(invoiceNumber, clientNumber);
  useSelectLocale(data.locale);
  useDocumentTitle(data?.brand?.name ?? "Payments - Pending");

  if (hasError) return <NotFound />;

  return (
    <Flex flexWrap="wrap" w="100%" align="center" justify="center" bg="white">
      <Flex
        width={["100%", "100%", "100%", "50%"]}
        justify={["center", "center", "center", "flex-end"]}
        p={[3, 3, 3, 6]}
      >
        {isReady && (
          <ProductOverview data={data} invoiceNumber={invoiceNumber} />
        )}
      </Flex>
      <Flex
        p={[3, 3, 3, 6]}
        width={["100%", "100%", "100%", "50%"]}
        h={["auto", "auto", "auto", "100vh"]}
        align="center"
        justify={["center", "cener", "center", "flex-start"]}
        boxShadow={["none", "none", "none", "md"]}
        position="relative"
        flexWrap={["wrap", "wrap", "wrap", "nowrap"]}
      >
        {data.brand && <BrandHeader brand={data.brand?.name} />}
        <Box
          maxW={["385px", "400px", "420px", "420px"]}
          minH="270px"
          ml={[0, 0, 0, "10%"]}
          mb={[0, 0, 0, 6]}
          p={2}
          w="100%"
        >
          <FeedbackMessage
            status={PENDING}
            locale={data.locale}
            brand={data.brand?.name}
          />
          <Box display={["block", "block", "block", "none"]}>
            <TermsAndPrivacyLinks />
          </Box>
        </Box>
      </Flex>
    </Flex>
  );
}

export default Pending;

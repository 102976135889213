import React from "react";
import LazyLoad from 'react-lazy-load';

const withLazyLoad = (WrappedComponent: React.ComponentType): React.ComponentType => () => (
  <LazyLoad>
    <WrappedComponent />
  </LazyLoad>
);

export default withLazyLoad;

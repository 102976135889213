import { Routes, Route } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import withRouterValidation from "./utils/withRouterValidation";
import withSuspense from "./utils/withSuspense";
import TagManager, { DataLayerArgs } from "react-gtm-module";
import Pay from "./views/Pay";
import SuccessPlatform from "./views/SuccessPlatform";
import PendingPlatform from "./views/PendingPlatform";
import ErrorViewPlatform from "./views/ErrorPlatform";
import Success from "./views/Success";
import Pending from "./views/Pending";
import ErrorView from "./views/Error";
import FindInvoice from "./views/FindInvoice";
import NotFound from "./views/NotFound";
import withLazyLoad from "./utils/withLazyLoad";

interface TagManagerArgs extends DataLayerArgs {
  // GTM id, must be something like GTM-000000.
  gtmId: string;
  // Additional events such as 'gtm.start': new Date().getTime(),event:'gtm.js'.
  events?: object | undefined;
  // Used to set environments.
  auth?: string | undefined;
  // Used to set environments, something like env-00.
  preview?: string | undefined;
}

const tagManagerArgs: TagManagerArgs = {
  gtmId: import.meta.env.VITE_APP_GTM_ID as string,
};

TagManager.initialize(tagManagerArgs);

export default function App() {
  // const [showMaintananceMsg, setShowMaintananceMsg] = useState(false);

  // useEffect(() => {
  //   if (showMaintananceMsg) {
  //     const showReal = window?.location?.href
  //       ?.split("?")?.[1]
  //       ?.includes("test");
  //     setShowMaintananceMsg(!showReal);
  //   }
  // }, [showMaintananceMsg]);
  // if (showMaintananceMsg) {
  //   return (
  //     <Routes>
  //       <Route
  //         path="/:locale/:brand/"
  //         element={withSuspense(withRouterValidation(WorkingOnIt))}
  //       />
  //       <Route path="/" element={withSuspense(WorkingOnIt)} />
  //       <Route path="*" element={withSuspense(NotFound)} />
  //     </Routes>
  //   );
  // }
  return (
    <Routes>
      <Route path="/pay/:invoiceHash/:clientHash" element={withSuspense(withLazyLoad(Pay))} />
      <Route
        path="/:locale/:brand/success/:txtid"
        element={withSuspense(withRouterValidation(withLazyLoad(SuccessPlatform)))}
      />
      <Route
        path="/:locale/:brand/pending/:txtid"
        element={withSuspense(withRouterValidation(withLazyLoad(PendingPlatform)))}
      />
      <Route
        path="/:locale/:brand/error"
        element={withSuspense(withRouterValidation(withLazyLoad(ErrorViewPlatform)))}
      />
      <Route
        path="/success/:invoiceHash/:clientHash/:txtid"
        element={withSuspense(withLazyLoad(Success))}
      />
      <Route
        path="/pending/:invoiceHash/:clientHash/:txtid"
        element={withSuspense(withLazyLoad(Pending))}
      />
      <Route
        path="/error/:invoiceHash/:clientHash"
        element={withSuspense(withLazyLoad(ErrorView))}
      />
      <Route
        path="/:locale/:brand/"
        element={withSuspense(withRouterValidation(withLazyLoad(FindInvoice)))}
      />
      <Route path="/" element={withSuspense(withLazyLoad(FindInvoice))} />
      <Route path="*" element={withSuspense(withLazyLoad(NotFound))} />
    </Routes>
  );
}

import React from "react";
import { isString } from "lodash";
import { useTranslation } from "react-i18next";
import { Box, Flex, Text, Link } from "@chakra-ui/react";
import useBrand from "../hooks/useBrand";
import { useAppState } from "../store";
import { InvoiceInfo } from "../types";
import { useLocation } from "react-router-dom";
import { dataLayerPush } from "../utils/datalayerPush";
import { PRIVACY_URL, TERMS_URL } from "../constants";

interface Props {
  color?: string;
}
function TermsAndPrivacyLinks({ color = "grey" }: Props) {
  const { t } = useTranslation();
  const brand = useBrand();
  const { locale } = useAppState() as InvoiceInfo;
  const brandName = isString(brand) ? brand : brand?.name;
  const location = useLocation();

  const handleDatalayer = () => {
    dataLayerPush({
      event: "payment_page",
      eventCategory: "after_payment_success",
      eventAction: "terms_condition_click",
      eventLabel: location.pathname,
    });
  };

  return (
    <Flex w="100%" justify="center" mt={[6, 6, 6, 12]} style={{ zIndex: 2 }}>
      <Text color={`${color}.500`} fontSize="sm" onClick={handleDatalayer}>
        <Link target="_blank" href={TERMS_URL}>
          {t("msg#terms-and-conditions")}
        </Link>
      </Text>
      <Box mx={2} borderLeft="1px solid #b2bed07a" />
      <Text color={`${color}.500`} fontSize="sm">
        <Link target="_blank" href={PRIVACY_URL}>
          {t("msg#privacy")}
        </Link>
      </Text>
    </Flex>
  );
}

export default TermsAndPrivacyLinks;

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
import React, { memo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Button, Flex, FormLabel, Spinner, Text } from "@chakra-ui/react";
import { GroupDataForm, PayOneHashResponse, PlatformDataForm } from "../types";
import {
  getErrorUrlBase,
  getPayoneConfig,
  getPayoneLanguage,
  getPendingUrlBase,
  getSuccessUrlBase,
  getUniqueReference,
} from "../utils/getPayoneConfig";
import { getCode } from "../utils/getValidProjectLocale";
import { getAuthorizationHash, getCreditCheckHash } from "./PayOneIframe.api";

declare global {
  interface Window {
    Payone: any;
    checkCallback: any;
    callbackAuthorization: any;
  }
}

// eslint-disable-next-line
const Payone = window.Payone;

interface CustomData {
  brand: string;
  locale: string;
  invoiceNumber: string;
  amountToPay: number | string;
  currency: string;
  groupData?: GroupDataForm;
  platformData?: PlatformDataForm;
}

interface Props {
  data: CustomData;
  onBack: () => void;
  invoiceHash?: string;
  clientHash?: string;
}
function PayOneIframe({
  data,
  onBack,
  invoiceHash,
  clientHash,
}: Props): React.ReactElement {
  const { t } = useTranslation();
  const [iframe, setIframe] = useState(null);
  const [hashCheckResponse, setCheckHashResponse] =
    useState<PayOneHashResponse | null>(null);
  const [isLoading, setLoading] = useState(false);
  const [isProcessing, setProcessing] = useState(false);
  const [isIframeLoading, setIframeLoading] = useState(false);
  const [error, setError] = useState("");
  const [localLanguage, setLocalLanguage] = useState(
    Payone.ClientApi.Language.en
  );
  const {
    brand,
    locale,
    invoiceNumber,
    amountToPay: amount,
    groupData,
    currency,
  } = data;
  const isPlatform = false;

  const onRedirect = (url: string) => {
    window.location.replace(url);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  function callbackAuthorization(response: any) {
    const urlSuccess = getSuccessUrlBase(
      brand,
      locale,
      invoiceHash,
      clientHash,
      response.txid
    );
    const urlPending = getPendingUrlBase(
      brand,
      locale,
      invoiceHash,
      clientHash,
      response.txid
    );
    if (response.status === "REDIRECT") {
      onRedirect(response.redirecturl);
    } else if (response.status === "APPROVED") {
      onRedirect(urlSuccess);
    } else if (response.status === "ERROR") {
      setError(response.errormessage);
    } else if (response.status === "PENDING") {
      onRedirect(urlPending);
    }
    setProcessing(false);
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  function callbackCheckCreditCard(response: any) {
    if (response.status === "VALID") {
      startAuthorization(response.pseudocardpan);
    } else {
      setProcessing(false);
      setError(response.errormessage);
    }
  }

  const startAuthorization = async (pseudocardpan: string) => {
    if (!hashCheckResponse) return;
    const successurl = getSuccessUrlBase(
      brand,
      locale,
      invoiceHash,
      clientHash
    );
    const errorurl = getErrorUrlBase(brand, locale, invoiceHash, clientHash);
    const formatedAmount = parseInt(amount as string, 10) * 100;
    const param = `${getCode(brand)}|${isPlatform}|${invoiceNumber}`;
    const customerid = groupData?.client_id;

    const serializableData = {
      amount: formatedAmount,
      successurl,
      errorurl,
      reference: getUniqueReference(invoiceNumber),
      invoiceid: invoiceNumber,
      currency,
      param,
      customerid,
    };
    const { hash: authHash, error: authError } =
      await getAuthorizationHash(serializableData);
    if (authError) {
      setError(authError);
      return;
    }

    const additionalData = {
      firstname: groupData?.firstName,
      lastname: groupData?.lastName,
      country: groupData?.client_country ?? "DE",
    };

    const { responsetype, apiVersion, mode, mid, aid, encoding, portalid } =
      hashCheckResponse;

    const dataAuth = {
      aid,
      api_version: apiVersion,
      clearingtype: "cc",
      encoding,
      hash: authHash,
      mid,
      mode,
      portalid,
      pseudocardpan,
      request: "authorization",
      responsetype,
      ...serializableData,
      ...additionalData,
    };
    console.log("authorization", dataAuth);
    const r = new Payone.ClientApi.Request(dataAuth, {
      callbackFunctionName: "callbackAuthorization",
    });
    r.send();
  };

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (iframe === null) {
      setError("Not ready, please reload");
      return;
    }
    // @ts-ignore
    if (iframe.isComplete()) {
      // @ts-ignore
      iframe.creditCardCheck("checkCallback");
      setProcessing(true);
    } else {
      // @ts-ignore
      if (!iframe.isCardpanComplete()) {
        setError(localLanguage.invalidCardpan);
        // @ts-ignore
      } else if (!iframe.isCardTypeComplete()) {
        setError(localLanguage.invalidCardtype);
        // @ts-ignore
      } else if (!iframe.isExpireMonthComplete()) {
        setError(localLanguage.invalidExpireDate);
        // @ts-ignore
      } else if (!iframe.isExpireYearComplete()) {
        setError(localLanguage.invalidExpireDate);
        // @ts-ignore
      } else if (!iframe.isCvcComplete()) {
        setError(localLanguage.invalidCvc);
      } else {
        setError("Internal Error");
      }

      console.log("Is not complete", iframe);
    }
  };

  // first call: get payone data for credit card check
  // token, aid, portialid ...
  useEffect(() => {
    (async () => {
      setLoading(true);
      console.log("Getting first payone hash and data");
      const meta = {
        invoiceNumber,
        reservationId: groupData?.reservationId,
        clientNumber: groupData?.client_id,
      };
      const { payoneHashReponse, error: creditCheckError } =
        await getCreditCheckHash({
          amount: parseInt(amount as string, 10),
          currency,
          isPlatform,
          ...meta,
        });
      setLoading(false);
      if (creditCheckError) {
        setError(t(creditCheckError));
      } else if (payoneHashReponse) {
        setCheckHashResponse(payoneHashReponse);
      }
    })();
    // eslint-disable-next-line
  }, [amount, brand, currency, t]);

  // second call init iframe
  useEffect(() => {
    if (hashCheckResponse && !iframe) {
      const language = getPayoneLanguage(locale, Payone);
      setLocalLanguage(language);
      const config = getPayoneConfig(() => setIframeLoading(false), language);

      const {
        request,
        responsetype,
        apiVersion,
        mode,
        mid,
        aid,
        encoding,
        portalid,
        hash,
      } = hashCheckResponse;

      const requestObj = {
        aid,
        api_version: apiVersion,
        encoding,
        hash,
        mid,
        mode,
        portalid,
        request,
        responsetype,
        storecarddata: "yes", // fixed value
      };

      window.checkCallback = callbackCheckCreditCard;
      window.callbackAuthorization = callbackAuthorization;
      setIframeLoading(true);
      console.log("Init Iframe", config, requestObj);
      const iframes = new Payone.ClientApi.HostedIFrames(config, requestObj);
      setIframe(iframes);
    }
  }, [
    hashCheckResponse,
    locale,
    callbackAuthorization,
    iframe,
    callbackCheckCreditCard,
  ]);

  if (isLoading) return <Spinner size="md" />;

  return (
    <div>
      <Box>
        <Text mb={1} fontWeight="bold">
          {t("msg#please-note", "Please note")}
        </Text>
        <Text mb={4}>
          {t(
            "msg#please-note-that-the-entire-credit-card-payment-process-takes-place-in",
            "Please note that the entire credit card payment process takes place in a secure iframe environment."
          )}
          {t(
            "msg#no-credit-card-details-will-be-saved-or-forwarded-to-third-parties",
            "Your credit card details will not be saved or forwarded to third parties."
          )}
          <br />
          <br />
          {t(
            "some-credit-card-payments-are-declined-by-the-card-issuing-bank",
            "Some credit card payments are declined by the card-issuing bank because all credit cards have a maximum limit for individual payments. If a payment is declined, please try to spread the entire payment over several transactions."
          )}
        </Text>
        <Text mb={1} fontWeight="bold">
          {t("msg#amount", "Amount")}
        </Text>
        <Text mb={4}>
          {amount.toLocaleString("de")} {currency}
        </Text>
      </Box>
      {(isProcessing || isIframeLoading) && (
        <Flex justifyContent="center">
          <Spinner size="xl" />
        </Flex>
      )}
      <Box
        as="form"
        id="payment-form"
        onSubmit={handleSubmit}
        className="CreditCard"
        borderRadius="7px"
        boxShadow="md"
        alignSelf="center"
        w={["100%", "90%", "95%"]}
        p="2em"
        m="0 auto"
        mt={6}
        style={isProcessing || isIframeLoading ? { display: "none" } : {}}
      >
        {hashCheckResponse && (
          <>
            <Box id="cardtype" className="inputIframe" />
            <FormLabel htmlFor="cardpan" color={`${getCode(brand)}.800`}>
              {t("msg#credit-card-number", "Card Number")}
            </FormLabel>
            <Box id="cardpan" className="inputIframe" />

            <Flex direction={["column", "row", "row"]}>
              <Box w={["100%", "100%", "50%"]}>
                <FormLabel
                  htmlFor="expireInput"
                  color={`${getCode(brand)}.800`}
                >
                  {t("msg#expiry-date", "Expiry Date")}
                </FormLabel>
                <Flex
                  id="expireInput"
                  className="inputIframe"
                  style={{ marginTop: "5px" }}
                  width="100%"
                >
                  <Box id="cardexpiremonth" w={["100%", "100%", "45%"]} />
                  <Box id="cardexpireyear" w={["100%", "100%", "45%"]} />
                </Flex>
              </Box>
              <Box w={["100%", "100%", "50%"]}>
                <FormLabel htmlFor="cardcvc2" color={`${getCode(brand)}.800`}>
                  {t("msg#security-code", "Security Code ")}
                </FormLabel>
                <Box id="cardcvc2" className="inputIframe" />
              </Box>
            </Flex>
            <Button
              mb={2}
              type="submit"
              width="100%"
              isDisabled={isProcessing}
              isLoading={isProcessing}
              borderRadius="0 0 4px 4px"
            >
              <span>
                {t("msg#pay-currency-amount", {
                  CURRENCY: currency,
                  AMOUNT: amount.toLocaleString("de"),
                  defaultValue: "Pay %CURRENCY% %AMOUNT%",
                })}
              </span>
            </Button>
          </>
        )}
        {/* Show any error that happens when processing the payment */}
        <Text
          id="errorOutput"
          role="alert"
          color="red.500"
          fontWeight="600"
          fontSize="sm"
        >
          {error}
        </Text>
      </Box>
      <Button w="100%" mt={6} onClick={onBack} isDisabled={isProcessing}>
        <span>{t("msg#back", "Back")}</span>
      </Button>
    </div>
  );
}

export default memo(PayOneIframe);

const getCurrencySymbol = (locale: string = "de", currency: string): string => {
  const n = 0;
  try {
    const parsed = n.toLocaleString(locale, {
      style: "currency",
      currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
    return parsed.replace(/\d/g, "").trim();
  } catch(err) {
    console.error('Error: invalid currency:', currency)
    console.error(err);
    return '€';
  }
};

export default getCurrencySymbol;

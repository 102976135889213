import React, { useState, useEffect } from "react";
import { Spinner, Box, Flex } from "@chakra-ui/react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { decode } from "../utils/Base64";
import useInvoiceInfo from "../hooks/useInvoiceInfo";
import useSelectLocale from "../hooks/useSelectLocale";
import useDocumentTitle from "../hooks/useDocumentTitle";
import { IError, NORMAL, SUCCESS, NOT_READY, RouteParams } from "../types";
import { getCode } from "../utils/getValidProjectLocale";
import SetAmount from "../components/SetAmount";
import ProductOverview from "../components/ProductOverview";
import TermsAndPrivacyLinks from "../components/TermsAndPrivacyLinks";
// import CheckoutForm from '../components/CheckoutForm';
import BrandHeader from "../components/BrandHeader";
import FeedbackMessage from "../components/FeedbackMessage";
import PayOneIframe from "../components/PayOneIframe";
import NotFound from "./NotFound";
import { dataLayerPush } from "../utils/datalayerPush";

function Pay() {
  const [step, setStep] = useState(0);
  const [finalAmount, setFinalAmount] = useState(0);
  const [errors, setErrors] = useState<IError[]>([]);
  const [status, setStatus] = useState(NORMAL);
  const { invoiceHash, clientHash } = useParams() as RouteParams;

  const location = useLocation();
  const navigate = useNavigate();
  const invoiceNumber = decode(invoiceHash);
  const lastName = decode(clientHash);
  const {
    isLoading: isInitializing,
    isFetched: isReady,
    data,
    hasError,
  } = useInvoiceInfo(invoiceNumber, lastName);
  useSelectLocale(data.locale);
  useDocumentTitle(data.brand?.name ?? "Payments - Pay");
  const brandName = getCode(data.brand?.name);

  useEffect(() => {
    if (data && data.remaining !== undefined && data.remaining <= 0) {
      setStatus(data.total === null ? NOT_READY : SUCCESS);
    }
  }, [data]);
  console.log(errors);

  const onNext = async (amount: number) => {
    if (data && data.remaining !== undefined && amount > data.remaining) {
      setErrors([
        {
          msg: "msg#the-amount-exceed-the-total-to-pay",
          showPrice: true,
        },
      ]);

      return;
    }
    if ((data && data.remaining !== undefined && amount < 1) || isNaN(amount)) {
      setErrors([{ msg: "incorrect-amount" }]);
      return;
    }
    dataLayerPush({
      event: "payment_page",
      eventCategory: "payment_detail",
      eventAction: "next",
      eventLabel: location.pathname,
    });
    setFinalAmount(amount);
    setStep((prevStep: number) => prevStep + 1);
  };

  const onCancel = () => {
    navigate(`/${data.locale}/${getCode(data.brand?.name)}`, { replace: true });
  };

  const onBack = () => {
    dataLayerPush({
      event: "payment_page",
      eventCategory: "card_abondon_click",
      eventAction: "back",
      eventLabel: location.pathname,
    });
    setStep((prevStep: number) => prevStep - 1);
  };

  if (hasError) return <NotFound />;
  console.log(finalAmount);

  return (
    <Flex flexWrap="wrap" w="100%" align="center" justify="center" bg="white">
      <Flex
        width={["100%", "100%", "100%", "50%"]}
        justify={["center", "center", "center", "flex-end"]}
        p={[3, 3, 3, 6]}
      >
        {isReady && (
          <ProductOverview
            data={data}
            onCancel={onCancel}
            invoiceNumber={invoiceNumber}
          />
        )}
      </Flex>
      <Flex
        width={["100%", "100%", "100%", "50%"]}
        minH={["auto", "auto", "auto", "100vh"]}
        h="100%"
        justify={["center", "center", "center", "flex-start"]}
        boxShadow={["none", "none", "none", "md"]}
        flexWrap="wrap"
        className="RightContainer"
      >
        {data.brand && <BrandHeader brand={brandName} />}
        <Box
          maxW={["385px", "400px", "420px", "480px"]}
          minH="270px"
          mt={[0, 0, 0, "92px"]}
          ml={[0, 0, 0, "10%"]}
          mb={[0, 0, 0, 6]}
          p={2}
          w="100%"
          className="RightPanelContent"
        >
          {status !== NORMAL && (
            <FeedbackMessage
              status={status}
              brand={brandName}
              locale={data.locale}
            />
          )}
          {isInitializing && (
            <Flex justifyContent="center">
              <Spinner size="xl" />
            </Flex>
          )}
          {step === 0 && isReady && status === NORMAL && (
            <SetAmount data={data} onNext={onNext} errors={errors} />
          )}
          {step === 1 && isReady && status === NORMAL && (
            <PayOneIframe
              onBack={onBack}
              invoiceHash={invoiceHash}
              clientHash={clientHash}
              data={{
                brand: data.brand?.name,
                locale: data.locale,
                invoiceNumber,
                amountToPay: finalAmount,
                currency: data.currency?.abbreviation ?? "",
                groupData: {
                  ...data,
                  firstName: data?.client?.first_name,
                  lastName: data?.client?.last_name,
                  currency: data.currency?.abbreviation ?? "",
                  client_country: data?.client?.country?.code ?? "DE",
                  client_id: data?.client?.id, // this is actually reservation_client_id because payone requires an integer value and we are using UUID
                },
              }}
            />
          )}
          <Box display={["block", "block", "block", "none"]}>
            <TermsAndPrivacyLinks />
          </Box>
        </Box>
      </Flex>
    </Flex>
  );
}

export default Pay;

import React from "react";
import { CircularProgress } from "@chakra-ui/react";

const Loader = () => (
  <div
    style={{
      width: "100%",
      height: "100vh",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    <CircularProgress isIndeterminate />
  </div>
);

const withSuspense = (WrappedComponent: React.ComponentType) => (
  <React.Suspense fallback={<Loader />}>
    <WrappedComponent />
  </React.Suspense>
);

export default withSuspense;

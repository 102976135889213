/* eslint-disable react/no-danger */
import React from "react";
import { capitalize, map } from "lodash";
import { Icon, Flex, Text, Link, Button } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { RiAlertFill, RiErrorWarningFill } from "react-icons/ri";
import { HiCheck } from "react-icons/hi";
import { NOT_READY, SUCCESS, FAIL, PENDING } from "../types";
import { getWebpageBase } from "../utils/getBrandLinks";
import { isPlatform } from "../utils/getValidProjectLocale";
import { dataLayerPush } from "../utils/datalayerPush";
import { useLocation } from "react-router-dom";

type FeedbackMessageProps = {
  status: string;
  txtid?: string;
  locale: string;
  brand: string;
};

const sucessBg =
  "linear-gradient(120deg, rgba(14,186,134,1) 33%, rgba(0,255,53,1) 100%);";

const baseProps = {
  fontSize: "4xl",
  color: "white",
  w: "100px",
  h: "100px",
};

export const titleCase = (str: string = ""): string =>
  map(str.split(" "), capitalize).join(" ");

function FeedbackMessage({
  status,
  txtid,
  brand,
  locale,
}: FeedbackMessageProps) {
  const { t } = useTranslation();
  const location = useLocation();
  let msgProps;
  let titleColor;
  let title;
  let body;

  switch (status) {
    case SUCCESS:
      msgProps = {
        ...baseProps,
        as: HiCheck,
        background: sucessBg,
        borderRadius: "50%",
        p: 4,
      };
      titleColor = "green.400";
      title = t("msg#your-payment-was-successful-thank-you")?.replaceAll(
        "Viventura",
        brand
      );
      body = isPlatform(brand) ? (
        <span className="text-center" style={{ display: "block" }}>
          <span>
            <strong>
              {t("msg#payment-id-txtid", {
                TXTID: txtid, // FIXME
                interpolation: { prefix: "%", suffix: "%" },
                defaultValue: "Payment ID %TXTID%",
              })}
            </strong>
          </span>
          <br />
          <span>
            {t(
              "msg#a-confirmation-email-will-be-sent-to-email-shortly",
              "A confirmation email will be sent to you shortly"
            )}
          </span>
        </span>
      ) : (
        ""
      );
      break;
    case FAIL:
      msgProps = {
        ...baseProps,
        as: RiAlertFill,
        color: "red.500",
        background: "transparent",
      };
      titleColor = "red.600";
      title = t("msg#your-payment-has-failed-because");
      body = (
        <>
          <ul>
            <li>{t("msg#your-card-has-no-funds")}</li>
            <li>{t("msg#your-bank-declined-payment")}</li>
            <li>{t("msg#you-have-been-inactive-30")}</li>
          </ul>
          <br />
          {t("msg#please-contact-bank")}
        </>
      );
      break;
    case PENDING:
      msgProps = {
        ...baseProps,
        as: RiErrorWarningFill,
        color: "blue.500",
        background: "transparent",
      };
      titleColor = "blue.600";
      title = t("your-payment-is-beign-processed");
      body = (
        <>
          <span>
            {t(
              "msg#a-confirmation-email-will-be-sent-to-email-shortly",
              "A confirmation email will be sent to you shortly"
            )}
          </span>
        </>
      );
      break;
    case NOT_READY:
      msgProps = {
        ...baseProps,
        as: RiErrorWarningFill,
        color: "blue.500",
        background: "transparent",
      };
      titleColor = "blue.600";
      title = t("your-invoice-is-not-ready", "Your invoice is not ready");
      body = "";
      break;
    default:
      return null;
  }

  const handleDatalayer = () => {
    dataLayerPush({
      event: "payment_page",
      eventCategory: "after_payment_success",
      eventAction: "back_to_brand",
      eventLabel: location.pathname,
    });
  };

  return (
    <Flex direction="column" align="center">
      <Icon {...msgProps} />
      <Text fontSize="2xl" as="h2" mt={4} color={titleColor}>
        <span dangerouslySetInnerHTML={{ __html: title }} />
      </Text>
      <Text color="gray.500" mt={2}>
        {body}
      </Text>
      {status === SUCCESS && (
        <Link
          mt={3}
          href={getWebpageBase(brand, locale || "com").base}
          onClick={handleDatalayer}
        >
          <Button variant="ghost">
            {t("msg#back-to-brand", "Back to %BRAND%", {
              BRAND: titleCase(brand),
            })}
          </Button>
        </Link>
      )}
    </Flex>
  );
}

export default FeedbackMessage;
